:host,
:root {
  --primary: #285aff;
  --primary-50: #e5ebff;
  --primary-100: #bfceff;
  --primary-200: #94adff;
  --primary-300: #698cff;
  --primary-400: #4873ff;
  --primary-500: var(--primary);
  --primary-600: #2452ff;
  --primary-700: #1e48ff;
  --primary-800: #183fff;
  --primary-900: #0f2eff;
  --primary-A100: #ffffff;
  --primary-A200: #f7f8ff;
  --primary-A400: #c4caff;
  --primary-A700: #abb3ff;

  --contrast-50: #000000;
  --contrast-100: #000000;
  --contrast-200: #000000;
  --contrast-300: #000000;
  --contrast-400: #ffffff;
  --contrast-500: #ffffff;
  --contrast-600: #ffffff;
  --contrast-700: #ffffff;
  --contrast-800: #ffffff;
  --contrast-900: #ffffff;
  --contrast-A100: #000000;
  --contrast-A200: #000000;
  --contrast-A400: #000000;
  --contrast-A700: #000000;

  /** Secondary - Secondary v1.0 **/

  --secondary: #ed0000;
  --secondary-50: #fde0e0;
  --secondary-100: #fab3b3;
  --secondary-200: #f68080;
  --secondary-300: #f24d4d;
  --secondary-400: #f02626;
  --secondary-500: var(--secondary);
  --secondary-600: #eb0000;
  --secondary-700: #e80000;
  --secondary-800: #e50000;
  --secondary-900: #e00000;
  --secondary-A100: #ffffff;
  --secondary-A200: #ffd4d4;
  --secondary-A400: #ffa1a1;
  --secondary-A700: #ff8888;

  --secondary-contrast-50: #000000;
  --secondary-contrast-100: #000000;
  --secondary-contrast-200: #000000;
  --secondary-contrast-300: #ffffff;
  --secondary-contrast-400: #ffffff;
  --secondary-contrast-500: #ffffff;
  --secondary-contrast-600: #ffffff;
  --secondary-contrast-700: #ffffff;
  --secondary-contrast-800: #ffffff;
  --secondary-contrast-900: #ffffff;
  --secondary-contrast-A100: #000000;
  --secondary-contrast-A200: #000000;
  --secondary-contrast-A400: #000000;
  --secondary-contrast-A700: #000000;

  /** Grayscale Scale - Neutral v0.9 **/

  --neutral: #ffffff;
  --neutral-00: #f7f9fa;
  --neutral-05: #d7d7d7;
  --neutral-10: #a5a5a5;
  --neutral-20: #707070;
  --neutral-40: #374649;
  --neutral-60: #272727;
  --neutral-80: #03110e;

  /** Backgrounds **/

  --background: #fff;
  --background-01: #f7f9fa;
  --background-02: var(--neutral-00);

  /** Events and Alerts **/

  /** Success - OK v1.0 **/

  --success: #30af43;
  --success-medium: #59bf69;
  --success-light: #c1e7c7;

  /** Alerts - Warnings v1.0 **/

  --warning: #fa7921;
  --warning-medium: #ffa965;
  --warning-light: #ffe0ca;

  /** Errors - Errors v1.0 **/

  --error: #eb4747;
  --error-medium: #f17e7e;
  --error-light: #ffcaca;

  /** Thematic **/

  --water-20: #acdaec;
  --water: #73c0de;

  /** Transparencies **/

  /** White Transparencies - v1.0 **/

  --transparent-05: rgba(255, 255, 255, 0.05);
  --transparent-10: rgba(255, 255, 255, 0.1);
  --transparent-20: rgba(255, 255, 255, 0.2);
  --transparent-40: rgba(255, 255, 255, 0.4);
  --transparent-60: rgba(255, 255, 255, 0.6);
  --transparent-80: rgba(255, 255, 255, 0.8);

  /** Dark Transparencies - v1.0 **/

  --transparent-dark-05: rgba(0, 0, 0, 0.05);
  --transparent-dark-10: rgba(0, 0, 0, 0.1);
  --transparent-dark-20: rgba(0, 0, 0, 0.2);
  --transparent-dark-40: rgba(0, 0, 0, 0.4);
  --transparent-dark-60: rgba(0, 0, 0, 0.6);
  --transparent-dark-80: rgba(0, 0, 0, 0.8);

  /** Gradients v0.5 **/

  --gradient-primary: linear-gradient(315deg, #00446f 0%, #0070b5 100%);
  --gradient-secondary: linear-gradient(315deg, #56e39f 0%, #0070b7 100%);
  --gradient-neutral: linear-gradient(
    180deg,
    #5a5a5a 0%,
    rgba(255, 255, 255, 0) 100%
  );
  --gradient-03: linear-gradient(170.56deg, #ffffff 62.44%, #f7f9fa 154.48%);
  --gradient-login: linear-gradient(
    180deg,
    #f7f9fa 0%,
    #ffffff 238.36%,
    #f7f9fa 402.23%
  );

  /** Box-Shadow Shadows v1.0 **/

  --shadow-soft: 0px 2px 2px rgba(0, 0, 0, 0.15);
  --shadow-medium: 0px 4px 8px rgba(0, 0, 0, 0.15);
  --shadow-hard: 0px 8px 16px rgba(0, 0, 0, 0.2);

  /** Applications **/

  /** Texts **/

  --text-light: var(--neutral-40);
  --text-medium: var(--neutral-60);
  --text-dark: var(--neutral-80);
  --heading: var(--primary-500);
  --link: var(--primary-600);

  /** Colors **/

  --icon-color: var(--primary);
  --icon-neutral: var(--neutral-40);
  --icon-dark-color: var(--neutral-40);
  --icon-dark-background: var(--neutral-80);
  --icon-dark-neutral: var(--neutral-05);
}
